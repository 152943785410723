<template>
	<div v-if="show" :class="$style.overlay">
		<v-card :class="$style.modal">
			<v-card-title :class="$style.title">確認</v-card-title>
			<v-card-text :class="$style.message">{{ message }}</v-card-text>
			<v-card-actions :class="$style.buttonContainer">
				<v-spacer></v-spacer>
				<button :class="[$style.button, $style.cancelButton]" @click="handleCancel">キャンセル</button>
				<button :class="[$style.button, $style.confirmButton]" @click="handleConfirm">確認</button>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import VmButton from '../Atoms/VmButton.vue'
export default {
	name: 'VmConfirmModal',
	components: {
		VmButton
	},
	data: () => ({
		show: false,
		message: '',
		resolve: null,
		reject: null
	}),
	methods: {
		open(message) {
			this.message = message
			this.show = true
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		handleConfirm() {
			this.show = false
			this.resolve(true)
		},
		handleCancel() {
			this.show = false
			this.resolve(false)
		}
	}
}
</script>

<style module>
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999;
}

.modal {
	width: 90%;
	max-width: 400px;
	background-color: white !important;
}

.title {
	color: #6d6f6c !important;
	font-size: 18px !important;
	font-weight: bold !important;
	padding: 16px 24px !important;
}

.message {
	color: #6d6f6c !important;
	font-size: 14px !important;
	line-height: 1.6 !important;
	padding: 0 24px 16px !important;
	white-space: pre-line;
}

.buttonContainer {
	padding: 0 24px 24px !important;
	display: flex;
	gap: 12px;
}
.button {
	min-width: 100px;
	padding: 8px 16px;
	border-radius: 4px;
	font-weight: bold;
	font-size: 0.9em;
	border: none;
	cursor: pointer;
	transition: opacity 0.2s;
}

.button:hover {
	opacity: 0.8;
}

.cancelButton {
	background-color: #cfd2d1;
	color: white;
}

.confirmButton {
	background-color: #dcc268;
	color: white;
}
</style>
