import { render, staticRenderFns } from "./ConversationReservationFormCard.vue?vue&type=template&id=52eacfd5&scoped=true&"
import script from "./ConversationReservationFormCard.vue?vue&type=script&lang=js&"
export * from "./ConversationReservationFormCard.vue?vue&type=script&lang=js&"
import style0 from "./ConversationReservationFormCard.vue?vue&type=style&index=0&id=52eacfd5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52eacfd5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VIcon,VSelect,VTextarea})
