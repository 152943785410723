import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueHead from 'vue-head'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment-timezone'
import VueJsonToCsv from 'vue-json-to-csv'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import convertToPost from './util/convertToPost'
import convertToDateFormat from './util/convertToDateFormat'
import convertToAccurateTime from './util/convertToAccurateTime'
import divideStraddlingDaySchedule from './util/divideStraddlingDaySchedule'
import makeScheduleFromRegularInfo from './util/makeScheduleFromRegularInfo'
import modifySummerTimeLesson from './util/modifySummerTimeLesson'
import validationForRegularLessonTime from './util/validationForRegularLessonTime'
import compareTime from './util/compareTime'
import { i18n } from './i18n.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import mixinFunc from '../mixins/mixinFunc'
import VueScrollTo from 'vue-scrollto'
import { VueLoading } from 'vue-loading-template'
import limitedText30 from './util/limitText'
import sanitizeHTML from 'sanitize-html'
import VueGtm from 'vue-gtm'
import './validation' // VeeValidate の設定をインポート
import VmConfirmModal from '@/components/Molecules/VmConfirmModal.vue'

Vue.use(VueHead)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.component('VueJsonToCsv', VueJsonToCsv)
Vue.component('VueTimepicker', VueTimepicker)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('VueLoading', VueLoading)
Vue.mixin(mixinFunc)
Vue.use(VueScrollTo, {
	container: 'body',
	duration: 500,
	easing: 'ease',
	offset: 0,
	force: true,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true
})
Vue.use(VueGtm, {
	id: process.env.VUE_APP_GTM_ID,
	enabled: true,
	debug: true,
	loadScript: true,
	vueRouter: router
})

const ConfirmConstructor = Vue.extend(VmConfirmModal)
const confirmInstance = new ConfirmConstructor()
const vm = confirmInstance.$mount()
document.body.appendChild(vm.$el)

// moment.tz.setDefault('Asia/Tokyo')
Vue.prototype.$moment = moment
Vue.prototype.$convertToPost = convertToPost
Vue.prototype.$convertToDateFormat = convertToDateFormat
Vue.prototype.$convertToAccurateTime = convertToAccurateTime
Vue.prototype.$compareTime = compareTime
Vue.prototype.$divideStraddlingDaySchedule = divideStraddlingDaySchedule
Vue.prototype.$makeScheduleFromRegularInfo = makeScheduleFromRegularInfo
Vue.prototype.$modifySummerTimeLesson = modifySummerTimeLesson
Vue.prototype.$validationForRegularLessonTime = validationForRegularLessonTime
Vue.prototype.$limitedText30 = limitedText30
Vue.prototype.$sanitize = sanitizeHTML
Vue.prototype.$confirm = (message) => confirmInstance.open(message)

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.withCredentials = true

let isAlertShown = false // アラートが連続で表示されないようにフラグをセット

//エラーハンドリング
axios.interceptors.response.use(
	function (response) {
		isAlertShown = false
		return response
	},
	function (error) {
		console.log(error.response)
		if (error.response.status === 401 && !isAlertShown) {
			isAlertShown = true
			window.localStorage.removeItem('vuex')
			alert('アクセストークンが不正です。もう一度ログインして下さい。')
			router.push('/')
		}
		return Promise.reject(error)
	}
)

new Vue({
	router,
	vuetify,
	store,
	i18n,
	render: (h) => h(App),
	head: {
		title: {
			inner: 'vollmond-lesson-system'
		}
	}
}).$mount('#app')
